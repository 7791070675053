/**
 * The MDX layout is for pages generated via gatsby-plugin-mdx
 */

import * as React from 'react';
import { graphql } from 'gatsby';

import Markdown from '../components/Markdown';
import TableOfContents from '../components/TableOfContents';
import { PageQuery } from '../types/mdx';

import DocumentationLayout from '../components/DocumentationLayout';

interface Props {
  location: Location;
  data: PageQuery;
}

function DocumentationTemplate({ location, data }: Props) {
  const { mdx, next, previous } = data;

  const meta = {
    title: mdx.frontmatter.title || '',
    description: mdx.frontmatter.description || mdx.excerpt || '',
  };

  const hasTableOfContents = !!mdx.tableOfContents.items;

  return (
    <DocumentationLayout
      location={location}
      title={meta.title}
      description={meta.description}
      aside={
        hasTableOfContents ? (
          <TableOfContents
            path={mdx.parent.relativePath}
            contents={mdx.tableOfContents.items || []}
          />
        ) : null
      }
      next={next}
      previous={previous}
    >
      <Markdown>{mdx.body}</Markdown>
    </DocumentationLayout>
  );
}

export const pageQuery = graphql`
  query($id: String!, $next: String!, $previous: String!) {
    mdx: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
      }
      excerpt
      tableOfContents
      headings {
        depth
        value
      }
      parent {
        ... on File {
          relativePath
        }
      }
    }
    next: mdx(fields: { slug: { eq: $next } }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    previous: mdx(fields: { slug: { eq: $previous } }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;

export default DocumentationTemplate;
